import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const UsersIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
    >
      <path
        fill={props.colour ? props.colour : theme.colours.black}
        fillRule="evenodd"
        d="M18.072 17.417v1.833c0 .55-.402.917-1.004.917-.603 0-1.004-.367-1.004-.917v-1.833c0-1.559-1.305-2.75-3.012-2.75H5.02c-1.707 0-3.012 1.191-3.012 2.75v1.833c0 .55-.402.917-1.004.917S0 19.8 0 19.25v-1.833c0-2.567 2.209-4.584 5.02-4.584h8.032c2.811 0 5.02 2.017 5.02 4.584zm-14.056-11c0-2.567 2.209-4.584 5.02-4.584 2.811 0 5.02 2.017 5.02 4.584 0 2.566-2.209 4.583-5.02 4.583-2.811 0-5.02-2.017-5.02-4.583zm2.008 0c0 1.558 1.305 2.75 3.012 2.75s3.012-1.192 3.012-2.75c0-1.559-1.305-2.75-3.012-2.75s-3.012 1.191-3.012 2.75zm14.257 6.6c-.502-.092-1.105.183-1.205.641-.1.459.2 1.009.703 1.1 1.305.275 2.208 1.375 2.208 2.659v1.833c0 .55.402.917 1.004.917.603 0 1.004-.367 1.004-.917v-1.833c.1-2.109-1.405-3.942-3.714-4.4zm-4.016-11c-.502-.184-1.004.091-1.205.641-.1.459.2 1.009.703 1.1 1.606.367 2.61 1.834 2.208 3.392-.3 1.008-1.104 1.742-2.208 2.017-.502.091-.904.641-.703 1.1.1.458.502.733 1.004.733h.2c1.808-.458 3.213-1.65 3.615-3.3.703-2.567-.904-5.133-3.614-5.683z"
      />
    </svg>
  )
}

export default UsersIcon
