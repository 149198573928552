import * as React from 'react'
import styled from 'styled-components'
import theme from '../../atoms/theme'

interface Props {
  seatingStatus: string
}

const Container = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: 1.6rem;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem;
`

const EventCapacity = (props: Props): JSX.Element => {
  const getCapacityLabel = (): string => {
    switch (props.seatingStatus.toLowerCase()) {
      case 'max capacity':
        return 'Waitlist available'
      case 'seats available':
        return 'Spots available'
      default:
        return props.seatingStatus
    }
  }

  const getCapacityStyles = (): object => {
    switch (props.seatingStatus.toLowerCase()) {
      case 'max capacity':
        return {
          backgroundColor: theme.colours.white,
          border: `1px solid ${theme.colours.primary}`,
          color: theme.colours.primary,
        }
      case 'filling fast':
        return {
          color: theme.colours.white,
          backgroundColor: theme.colours.primary,
        }
      default:
        return {
          backgroundColor: theme.colours.success1,
          color: theme.colours.white,
        }
    }
  }

  return <Container style={getCapacityStyles()}>{getCapacityLabel()}</Container>
}

export default EventCapacity
