import * as React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'
import FacebookIcon from '../../atoms/graphics/facebook-icon'
import TwitterIcon from '../../atoms/graphics/twitter-icon'
import LinkedinIcon from '../../atoms/graphics/linkedin-icon'
import CommentIcon from '../../atoms/graphics/comment'

interface Props {
  eventUrl: string
  title: string
}

interface StyleProps {
  backgroundColour?: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const SocialLink = styled.div`
  height: 5rem;
  width: 5rem;
  margin-right: 1.5rem;
  border-radius: 50%;
  background-color: ${(props: StyleProps): string => props.backgroundColour};
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }
  }
`

const EventSocialLinks = (props: Props): JSX.Element => {
  return (
    <Container>
      <SocialLink backgroundColour={'#1d76f2'}>
        <FacebookShareButton quote={props.title} url={props.eventUrl}>
          <FacebookIcon />
        </FacebookShareButton>
      </SocialLink>
      <SocialLink backgroundColour={'#21a1f3'}>
        <TwitterShareButton title={props.title} url={props.eventUrl}>
          <TwitterIcon />
        </TwitterShareButton>
      </SocialLink>
      <SocialLink backgroundColour={'#0677b5'}>
        <LinkedinShareButton title={props.title} url={props.eventUrl}>
          <LinkedinIcon />
        </LinkedinShareButton>
      </SocialLink>
      <SocialLink backgroundColour={'#03cd49'}>
        <WhatsappShareButton title={props.title} url={props.eventUrl}>
          <CommentIcon />
        </WhatsappShareButton>
      </SocialLink>
    </Container>
  )
}

export default EventSocialLinks
