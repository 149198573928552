import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const CalendarIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.colour ? props.colour : theme.colours.black}
        fillRule="evenodd"
        d="M19 3h-2V2c0-.6-.4-1-1-1s-1 .4-1 1v1H9V2c0-.6-.4-1-1-1s-1 .4-1 1v1H5C3.3 3 2 4.3 2 6v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3zM5 5h2v1c0 .6.4 1 1 1s1-.4 1-1V5h6v1c0 .6.4 1 1 1s1-.4 1-1V5h2c.6 0 1 .4 1 1v3H4V6c0-.6.4-1 1-1zm14 16H5c-.6 0-1-.4-1-1v-9h16v9c0 .6-.4 1-1 1z"
      />
    </svg>
  )
}

export default CalendarIcon
